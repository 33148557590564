import React from "react";

import Image from "../components/v2/Image";
import Layout from "../components/v2/Layout";
import ContentBlock from "../components/v2/ContentBlock";
import Hero from "../components/v2/Hero";
import PageSection from "../components/v2/PageSection";
import ImageBlock from "../components/v2/ImageBlock";
import ImageGrid from "../components/v2/ImageGrid";

import Tabs from "../components/v2/ui-blocks/Tabs";
import PrevNextNavFooter from "../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import { pageLinks } from "../data/pageLinks.js";
import ImageScroller from "../components/v2/ui-blocks/ImageScroller";

import hero_img from "../images/3. Logo Page/TOY7296 LGP Logo Header Image.png";
import Toyota_Main_svg from "../images/logo/logos_logos_01_desktop.svg";

import Toyota_Main_Logo_H_svg from "../images/3. Logo Page/Toyota_Main_Logo_H.svg";
import Toyota_Main_Logo_V_svg from "../images/3. Logo Page/Toyota_Main_Logo_V.svg";

import Logo_LGP_H_svg from "../images/3. Logo Page/Logo_LGP_H.svg";
import Logo_LGP_Stacked_svg from "../images/3. Logo Page/Logo_LGP_Stacked.svg";
import Logo_LGP_V_svg from "../images/3. Logo Page/Logo_LGP_V.svg";

import lgp_horizontal_primary_800x400 from "../images/3. Logo Page/LGP Horizontal – Primary – 800x400.svg";
import lgp_vertical_primary_800x400 from "../images/3. Logo Page/LGP Vertical – Primary – 800x400.svg";

import VISNZ_RAV4_logo_horiz_black_RGB_2021 from "../images/3. Logo Page/rav4.svg";
import VISNZ_Corolla_logo_horiz_black_RGB_2021 from "../images/3. Logo Page/Corolla-LGP–LP-02.svg";
import VISNZ_Yaris_logo_horiz_black_RGB_2021 from "../images/3. Logo Page/yaris.svg";

import genuine_parts_primary_800x400 from "../images/3. Logo Page/Genuine Parts – Primary – 800x400.svg";
import hilux_primary_800x400 from '../images/3. Logo Page/Hilux – Primary – 800x400.svg'
import hilix_x_lgp_horizontal_primary_800x400 from "../images/3. Logo Page/Hilux x LGP Horizontal – Primary – 800x400.svg";
import lgp_primary_800x400 from "../images/3. Logo Page/LGP – Primary – 800x400.svg";
import toyota_primary_800x400 from "../images/3. Logo Page/Toyota – Primary – 800x400.svg";
import toyota_x_lgp_horizontal_800x400 from "../images/3. Logo Page/Toyota x LGP Horizontal – Primary – 800x400.svg";

import Toyota_Corporate_Logo from "../images/3. Logo Page/Toyota_Corporate_Logo.svg";

import configurations_1_800x400 from "../images/3. Logo Page/Configurations/Configurations 1 – 800x400.png"
import configurations_2_800x400 from "../images/3. Logo Page/Configurations/Configurations 2 – 800x400.png"

import logo_plaement_mobility_720x1280 from '../images/3. Logo Page/Logo Placement – Mobility – 1280px.jpg'
import logo_placement_hilux_1280x454 from "../images/3. Logo Page/Logo Placement – Hilux – 1280px.png";
import logo_placement_tdp_1280x454 from "../images/3. Logo Page/Logo Placement – TDP – 1280px.png";
import logo_placement_tss_905x1280 from "../images/3. Logo Page/Logo Placement – TSS – 1280px.jpg";

import toyota_lgp_horizontal_secondary_800x400 from "../images/3. Logo Page/Toyota LGP Horizontal – Secondary – 800x400.svg";

import toyota_x_lgp_horizontal_black_800x400 from '../images/3. Logo Page/Toyota x LGP Horizontal – Black – 800x400.svg'
import toyota_x_lgp_horizontal_red_800x400 from '../images/3. Logo Page/Toyota x LGP Horizontal – Red – 800x400.svg'
import toyota_x_lgp_horizontal_white_800x400 from '../images/3. Logo Page/Toyota LGP Horizontal – White – 800x400.svg'

import incorrect_use from "../images/3. Logo Page/Incorrect logo use 1 – 1600x800.jpg"

import logo_messaging_platform from "../images/3. Logo Page/Logo Messaging Platform 1280x453.jpg";

import logo_uv_retail_offer_1280x from "../images/3. Logo Page/UV Toyota Certified – 1280px.jpg";
import logo_uv_signature_class_1280x from "../images/3. Logo Page/UV Signature Class – 1280px.jpg";
import logo_nv_finance_offer_1280x from "../images/3. Logo Page/Logo NV Finance Offer – 1280px.png";
import logo_nv_retail_offer_1280x from "../images/3. Logo Page/Logo NV Retail Offer – 1280px.png";
import logo_aftersales_1280x from "../images/3. Logo Page/Logo Aftersales – 1280px.png";
import logo_evergreen_brand_1280x from "../images/3. Logo Page/Logo Evergreen Brand – 1280px.jpg";


// Sub brand Logos
// Vertical
import Genuine_Accessories_Vertical from "../images/3. Logo Page/Sub Brand Logos/Vertical/Genuine Accessories – Vertical 400x400.svg";
import Genuine_Parts_Vertical from "../images/3. Logo Page/Sub Brand Logos/Vertical/Genuine Parts – Vertical 400x400.svg";
import Service_Vertical from "../images/3. Logo Page/Sub Brand Logos/Vertical/Service – Vertical 400x400.svg";
import Service_Advantage_Vertical from "../images/3. Logo Page/Sub Brand Logos/Vertical/Service Advantage – Vertical 400x400.svg";
import Signature_Class_Vertical from "../images/3. Logo Page/Sub Brand Logos/Vertical/Signature Class – Vertical 400x400.svg";
import Toyota_Certified_Vertical from "../images/3. Logo Page/Sub Brand Logos/Vertical/Toyota Certified – Vertical 400x400.svg";
import Toyota_Financial_Services_Vertical from "../images/3. Logo Page/Sub Brand Logos/Vertical/Toyota Financial Services – Vertical 400x400.svg";
import Toyota_Insurance_Vertical from "../images/3. Logo Page/Sub Brand Logos/Vertical/Toyota Insurance – Vertical 400x400.svg";
import Warranty_Vertical from "../images/3. Logo Page/Sub Brand Logos/Vertical/Warranty – Vertical 400x400.svg";

// Horizontal
import Genuine_Accessories_Horizontal from "../images/3. Logo Page/Sub Brand Logos/Horizontal/Genuine Accessories – Horizontal 800x400.svg";
import Genuine_Parts_Horizontal from "../images/3. Logo Page/Sub Brand Logos/Horizontal/Genuine Parts – Horizontal 800x400.svg";
import Service_Horizontal from "../images/3. Logo Page/Sub Brand Logos/Horizontal/Service – Horizontal 800x400.svg";
import Service_Advantage_Horizontal from "../images/3. Logo Page/Sub Brand Logos/Horizontal/Service Advantage – Horizontal 800x400.svg";
import Signature_Class_Horizontal from "../images/3. Logo Page/Sub Brand Logos/Horizontal/Signature Class – Horizontal 800x400.svg";
import Toyota_Certified_Horizontal from "../images/3. Logo Page/Sub Brand Logos/Horizontal/Toyota Certified – Horizontal 800x400.svg";
import Toyota_Financial_Services_Horizontal from "../images/3. Logo Page/Sub Brand Logos/Horizontal/Toyota Financial Services – Horizontal 800x400.svg";
import Toyota_Insurance_Horizontal from "../images/3. Logo Page/Sub Brand Logos/Horizontal/Toyota Insurance – Horizontal 800x400.svg";
import Warranty_Horizontal from "../images/3. Logo Page/Sub Brand Logos/Horizontal/Warranty – Horizontal 800x400.svg";

const introText =
  "The Toyota brand stands for quality, reliability, and durability. Our logos and typography have been designed to reflect that.";

const Logo = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <Hero image={hero_img} text={introText} navindex="0" textPos={"center"} />
      <PageSection sectionId="primary-logos">
        <ContentBlock>
          <h2>Primary Logos</h2>
          <h3>Staging Platform</h3>
          <div className="text">
            <p>
              The staging platform is how we refer to the Toyota emblem when shown in the box surround. The staging platform allows the emblem to be anchored, impactful and instantly recognisable as Toyota. This replaces the previous chrome Toyota logo and should be applied to all newly created assets.
            </p>
            <p>
              The staging platform should only be used locked up with Toyota, or, Toyota & <em>Let’s&nbsp;Go&nbsp;Places</em>,  Vehicle Brand or a Toyota Sub-brand. There should never be more than one Staging Platform logo on each layout.
            </p>
            <p>
              Please do not create your own logo using the staging platform. Only use the logos provided and only as outlined in the brand guidelines.
            </p>
          </div>
        </ContentBlock>
        <ImageBlock>
          <Image width="100%" src={Toyota_Main_svg} />
        </ImageBlock>
        <ContentBlock>
          <h3>Six types of logos</h3>
          <div className="text">
            <p>
              We have the Toyota / <em>Let’s&nbsp;Go&nbsp;Places</em> logo, the Toyota Brand logo, the <em>Let’s&nbsp;Go&nbsp;Places</em> logo, and our various Vehicle Brand and Sub-brand logos. Each is comprised of three elements: the logotype, the Toyota emblem and the staging platform. Keeping to these three elements not only simplifies and unifies our brand design system, but also helps increase recognition of our products.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller
          section="logo"
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: toyota_x_lgp_horizontal_800x400,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: "Toyota <em>Let’s&nbsp;Go&nbsp;Places</em> lock up logo"
              }
            },
            {
              image: {
                svg: toyota_primary_800x400,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: 'Toyota logo'
              }
            },
            {
              image: {
                svg: lgp_primary_800x400,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: '<em>Let’s&nbsp;Go&nbsp;Places</em> logo'
              }
            },
            {
              image: {
                svg: hilux_primary_800x400,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: 'Hilux logo'
              }
            },
            {
              image: {
                svg: hilix_x_lgp_horizontal_primary_800x400,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: 'Hilux <em>Let’s&nbsp;Go&nbsp;Places</em> lock up'
              }
            },
            {
              image: {
                svg: genuine_parts_primary_800x400,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: 'Genuine Parts logo'
              }
            },
          ]}></ImageScroller>
      </PageSection>
      <PageSection sectionId="toyota">
        <ContentBlock>
          <h3>Toyota Brand logo</h3>
          <div className="text">
            <p>
              Our Toyota master logo is used in sales and brand communications such as internal announcements, collateral and sponsorships. It’s locked up with <em>Let’s&nbsp;Go&nbsp;Places</em> (<a href="#lets-go-places">see guidelines in <em>Let’s&nbsp;Go&nbsp;Places</em> section</a>), when a vehicle badge is used in the headline, or where multiple Sub-brands are being promoted. It should never be used in conjunction with any other staging platform logo.
            </p>
            <p>
              There is a vertical and horizontal configuration of this logo. Please do not adjust the size of Toyota in relation to the staging platform.
            </p>
          </div>
        </ContentBlock>

        <Tabs
          section="logo"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Main_Logo_H_svg,
                  style: {}
                }
              },
              label: "Horizontal"
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Main_Logo_V_svg,
                  style: {}
                }
              },
              label: "Vertical"
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="lets-go-places">
        <ContentBlock>
          <h2>
            <em>Let’s&nbsp;Go&nbsp;Places</em>
          </h2>
          <div className="text">
            <p>
              The <em>Let’s&nbsp;Go&nbsp;Places</em> logo is to be used only in instances where it’s unmistakably a Toyota Location e.g. Stores.
            </p>
            <p>
              There are 3 configurations of this logo, depending on the layout. It should never be used in conjunction with any other staging platform logo, nor should the size or placement of the <em>Let’s&nbsp;Go&nbsp;Places</em> be modified.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="lets-go-places"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_LGP_Stacked_svg
                }
              },
              label: "Horizontal Stacked"
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_LGP_H_svg
                }
              },
              label: "Horizontal"
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Logo_LGP_V_svg
                }
              },
              label: "Vertical"
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="vehicles">
        <ContentBlock>
          <h2>Vehicle Brand Campaign Logos</h2>
          <div className="text">
            <p>
              For Vehicle Brand Campaigns, the vehicle badge should be locked up with the staging platform to create a ‘Vehicle Brand Campaign logo’. These logos will be used on campaign elements including, but not limited to, print, digital, AV and OOH.
            </p>
            <p>
              The exception to this rule is when we use a product nameplate in the creative headline. In this case the Toyota / <em>Let’s&nbsp;Go&nbsp;Places</em> logo is to be used.
            </p>
            <p>
              The vehicle logo on it’s own is only to be used where it is obviously Toyota e.g.on Store collateral.
            </p>
            <p>The logos should never be used in conjunction with any other staging platform logo. Please use the logos provided and do not adjust the size or placement of the vehicle logo in relation to the staging platform.</p>
            <p>Other Vehicle Brand Campaign logos can be developed upon request. (Contact Toyota Marketing)</p>
          </div>
        </ContentBlock>
        <ImageScroller
          section="logo"
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: VISNZ_Corolla_logo_horiz_black_RGB_2021,
                style: { minHeight: "200px", minWidth: "200px" }
              }
            },
            {
              image: {
                svg: hilix_x_lgp_horizontal_primary_800x400,
                style: { minHeight: "200px", minWidth: "200px" }
              }
            },
            {
              image: {
                svg: VISNZ_RAV4_logo_horiz_black_RGB_2021,
                style: { minHeight: "200px", minWidth: "200px" }
              }
            },
            {
              image: {
                svg: VISNZ_Yaris_logo_horiz_black_RGB_2021,
                style: { minHeight: "200px", minWidth: "200px" }
              }
            }
          ]}></ImageScroller>
      </PageSection>
      <PageSection sectionId="Sub-brand">
        <ContentBlock>
          <h2>Sub-brand logos</h2>
          <div className="text">
            <p>
              There are a number of Toyota Sub-brands in New Zealand and each of
              these have their own Sub-brand logo. The logos use the staging
              platform to ensure that they are recognisable as being a Toyota
              brand and to create consistency, no matter the product, service or
              program.
            </p>
            <p>
              The design and configuration of each Sub-brand logo has been
              individually crafted and carefully considered, so please do not
              type set it manually.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="logo"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "scroller",
                images: [
                  {
                    image: {
                      svg: Genuine_Accessories_Horizontal,
                      style: { maxWidth: "400px" }
                    }
                  },
                  {
                    image: {
                      svg: Genuine_Parts_Horizontal,
                      style: { maxWidth: "400px" }
                    }
                  },
                  {
                    image: {
                      svg: Service_Horizontal,
                      style: { maxWidth: "400px" }
                    }
                  },
                  {
                    image: {
                      svg: Service_Advantage_Horizontal,
                      style: { maxWidth: "400px" }
                    }
                  },
                  {
                    image: {
                      svg: Signature_Class_Horizontal,
                      style: { maxWidth: "400px" }
                    }
                  },
                  {
                    image: {
                      svg: Toyota_Certified_Horizontal,
                      style: { maxWidth: "400px" }
                    }
                  },
                  {
                    image: {
                      svg: Warranty_Horizontal,
                      style: { maxWidth: "400px" }
                    }
                  },
                  {
                    image: {
                      svg: Toyota_Financial_Services_Horizontal,
                      style: { maxWidth: "400px" }
                    }
                  },
                  {
                    image: {
                      svg: Toyota_Insurance_Horizontal,
                      style: { maxWidth: "400px" }
                    }
                  }
                ]
              },
              label: "Horizontal"
            },
            {
              content: {
                style: "scroller",
                images: [
                  {
                    image: {
                      svg: Genuine_Accessories_Vertical
                    }
                  },
                  {
                    image: {
                      svg: Genuine_Parts_Vertical
                    }
                  },
                  {
                    image: {
                      svg: Service_Vertical
                    }
                  },
                  {
                    image: {
                      svg: Service_Advantage_Vertical
                    }
                  },
                  {
                    image: {
                      svg: Signature_Class_Vertical
                    }
                  },
                  {
                    image: {
                      svg: Toyota_Certified_Vertical
                    }
                  },
                  {
                    image: {
                      svg: Warranty_Vertical
                    }
                  },
                  {
                    image: {
                      svg: Toyota_Financial_Services_Vertical
                    }
                  },
                  {
                    image: {
                      svg: Toyota_Insurance_Vertical
                    }
                  }
                ]
              },
              label: "Vertical"
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="corporate-logo">
        <ContentBlock>
          <h2>Corporate Logo</h2>
          <div className="text">
            <p>
              Aside from the six logo types used for all Toyota New Zealand
              marketing and sales communications, there exists a corporate logo
              consisting of just the Toyota wordmark.
            </p>
            <p>
              This logo represents the Toyota Motor Corporation (TMC) brand and
              is used for all corporate communications. This logo should only be
              used when Toyota New Zealand are communicating at a corporate
              level and should never appear in consumer-facing sales and
              marketing activity, nor Store communications.
            </p>
          </div>
        </ContentBlock>
        <ImageBlock vertPadding={false}>
          <Image width="100%" src={Toyota_Corporate_Logo} />
        </ImageBlock>
      </PageSection>
      <PageSection sectionId="configurations">
        <ContentBlock>
          <h2>Configurations</h2>
          <h3>Clear space</h3>
          <div className="text">
            <p>
              Minimum clear space around the logo ensures visibility and
              clarity. The Toyota, <em>Let’s Go Places</em>, Vehicle Brand or
              Sub-brand logos should be kept clear of other logos and design
              elements. To measure clear space, the layout unit of measurement,
              "X," is used. "X" is derived from dividing the staging platform
              square into a 3x3 grid; each grid unit is equivalent to 1X. The
              minimum clear space required around the logo is 1X, both
              horizontally and vertically.
            </p>
            <p>
              The minimum clearance for the Toyota wordmark is defined by drawing a rectangle around the edges of the mark.
            </p>
            <p>The minimum distance between the rectangle and any element is a distance of ½ X. X being the height of the Toyota wordmark.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: configurations_1_800x400,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              }
            },
            {
              image: {
                svg: configurations_2_800x400,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              }
            },
          ]}
        ></ImageScroller>

        <ContentBlock>
          <h3>Minimum size</h3>
          <div className="text">
            <p>
              The minimum acceptable size refers to the width of the staging
              platform.
            </p>
            <p>
              The minimum size is 9mm wide in print and 24 pixels wide in
              digital.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="logo"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: lgp_horizontal_primary_800x400
                }
              },
              label: "Horizontal"
            },
            {
              content: {
                style: "image",
                image: {
                  svg: lgp_vertical_primary_800x400
                }
              },
              label: "Vertical"
            }
          ]}
        />

        <ContentBlock>
          <h3>Logo Placement</h3>
          <div className="text">
            <p>
              The recommended placement for the logo is the bottom right hand corner of artwork. Minimum size guide and clear space also to be taken into account.
              See below for some examples of logo placement across a number of creative layouts. There will be certain instances where the right of the layout isn’t best, in this case position the logo on the left.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: logo_plaement_mobility_720x1280,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              }
            },
            {
              image: {
                svg: logo_placement_hilux_1280x454,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              }
            },
            {
              image: {
                svg: logo_placement_tdp_1280x454,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              }
            },
            {
              image: {
                svg: logo_placement_tss_905x1280,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              }
            }
          ]}></ImageScroller>
      </PageSection>
      <PageSection sectionId="colour-variations">
        <ContentBlock>
          <h2>Colour Variations</h2>
          <h3>Full-colour logos - preferred</h3>
          <div className="text">
            <p>
              There are two full-color options available for each of the six
              approved types of logo. The full-colour primary logos should be
              used on a white or light background and the full-colour secondary
              logos should be used on a mid-value or dark background.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="colour-variations"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: toyota_x_lgp_horizontal_800x400,
                  style: {}
                }
              },
              label: "Full-Colour Primary"
            },
            {
              content: {
                style: "image",
                image: {
                  svg: toyota_lgp_horizontal_secondary_800x400,
                  style: { backgroundColor: "black" }
                }
              },
              label: "Full-Colour Secondary"
            }
          ]}
        />
        <ContentBlock>
          <h3>Single-colour options</h3>
          <div className="text">
            <p>
              When necessary single-colour options are available. They are black
              or Toyota Red on a white background, or a reversed white logo on a
              black or Toyota Red background.
            </p>
            <p>
              While the preferred background colours are white, black or Toyota
              Red, alternative-colour backgrounds may be used when a co-branding
              partner controls the background colour. In such an instance,
              ensure that legibility and impact are optimised.
            </p>
            <p>
              The Toyota logos should not be produced over a pattern or colour
              gradation that undermines legibility.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="logo"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: toyota_x_lgp_horizontal_red_800x400,
                }
              },
              label: "Red"
            },
            {
              content: {
                style: "image",
                image: {
                  svg: toyota_x_lgp_horizontal_black_800x400,
                }
              },
              label: "Black"
            },
            {
              content: {
                style: "image",
                image: {
                  svg: toyota_x_lgp_horizontal_white_800x400,
                }
              },
              label: "White"
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="hey-toyota-logo">
        <ContentBlock>
          <h2>Hey Toyota</h2>
          <div className="text">
            <p>
              There should only be one staging platform logo on each layout.
            </p>
            <p>
              In this case, the primary Toyota / <em>Let’s&nbsp;Go&nbsp;Places</em> logo
              is the hero and follows the guidelines set out under ‘Logo’.
            </p>
            <p>
              A standalone vehicle badge logo can also be used but cannot be locked up with the staging platform. The size and position of the vehicle logo is flexible to suit the design. Example of this within the body copy below.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: logo_messaging_platform,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: "New vehicle accessories offer"
              }
            },
            {
              image: {
                svg: logo_nv_retail_offer_1280x,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: "New vehicle retail offer"
              }
            },
            {
              image: {
                svg: logo_nv_finance_offer_1280x,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: "New vehicle finance offer"
              }
            },
          ]}
        ></ImageScroller>
        <ContentBlock>
          <div className="text">
            <h3>Logo use for After Sales and Used Vehicles</h3>
            <p>
              See below a breakdown of what logo should be used across these channels.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: logo_evergreen_brand_1280x,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: "Evergreen brand"
              }
            },
            {
              image: {
                svg: logo_uv_signature_class_1280x,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                },
              },
              caption: {
                copy: " Used vehicle retail offer - Signature Class"
              }
            },
            {
              image: {
                svg: logo_uv_retail_offer_1280x,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                },
              },
              caption: {
                copy: " Used vehicle retail offer - Toyota Certified"
              }
            },
            {
              image: {
                svg: logo_aftersales_1280x,
                style: {
                  maxHeight: "50rem",
                  maxWidth: "90vw",
                  width: "auto",
                  margin: "auto"
                }
              },
              caption: {
                copy: "After Sales"
              }
            },
          ]}
        ></ImageScroller>
      </PageSection>
      <PageSection sectionId="incorrect-use">
        <ContentBlock>
          <h2>Incorrect Usage</h2>
          <div className="text">
            <p>
              For both building brand awareness and protecting the trademark of
              our brand, it's important to retain consistent and correct use of
              all our logos. In general, do not alter any of the logos in any
              way. The following examples illustrate possible misuses and simple
              rules to follow. These examples apply across our entire suite of
              logos.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image width="full" src={incorrect_use}></Image>
        </ImageGrid>
      </PageSection>
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Introduction"
        previousUrl={pageLinks.intro}
        nextText="Next"
        nextHeader="Typography"
        nextUrl={pageLinks.typography}
      />
    </Layout >
  );
};

export default Logo;